.tabs {
	position: relative;
}

.tab-nav ul {
	padding: 0;
	margin: -0.8rem 0 1.5rem 0;
	display: flex;
	border-bottom: $border;

	&:not(:focus-within):not(:hover) a {
		color: $black;
	}

	li {
		list-style-type: none;
		padding: 0;
		background: none;
		margin: 0;

		&:not(:last-child) {
			margin-right: 1.5rem;
		}

		a {
			display: block;
			text-decoration: none;
			outline: 0;
			padding: 0.8rem 0 0.8rem 0;
			background: none;
			color: rgba($black, 0.5);
			font-size: 1rem;
			font-weight: 300;
			position: relative;

			&:after {
				content: "";
				display: block;
				width: 2.5rem;
				height: 1px;
				background: $black;
				position: absolute;
				left: 50%;
				bottom: -1px;
				margin-left: -1.25rem;
				transform: scale(0);
				transition: transform 0.2s ease-out;
				transform-origin: center center;
			}

			&:hover, &:active, &:hover {
				color: $black;
			}

			&.selected {
				color: $black;

				&:after {
					transform: scale(1);
				}
			}
		}
	}
}

.tab-content {
	.tab {
		width: 100%;
		opacity: 0;
		
		pointer-events: none;
		transition: none;

		&.selected {
			position: relative !important;
			opacity: 1;
			pointer-events: auto;
			transition: opacity 0.15s ease-in;
		}
	}
}

@media screen and (max-width: $lg) {
	.tab-nav ul {
		flex-wrap: wrap;
		padding-bottom: 1rem;

		&:not(:focus-within):not(:hover) a {
			color: rgba($black, 0.5);
		}

		li {

			a {
				padding-bottom: 0.25rem;

				&:after {
					width: 100%;
					margin-left: 0;
					left: 0;
				}

				&.selected {
					color: $black !important;
				}
			}
		}
	}
	
}
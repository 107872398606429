/* Links */

a {
	color: $black;
	text-decoration: none;
	transition: color 0.15s ease-out;

	&:hover, &:active, &:focus {
		color: rgba($black, 0.5);
	}
}

/* Heading */

h1, .h1 {
	font-weight: 800;
	color: $headingColor;
	font-size: 2.75rem;
	line-height: 1.1;
	margin: 0 0 1.3rem 0;
}
h2, .h2 {
	font-weight: 800;
	color: $headingColor;
	font-size: 2rem;
	line-height: 1.2;
	margin: 0 0 1rem 0;
}
h3, .h3 {
	font-weight: 500;
	color: $headingColor;
	font-size: 1.85rem;
	line-height: 1.3;
	margin: 0 0 1rem 0;
}
h4, .h4 {
	font-weight: 500;
	color: $headingColor;
	font-size: 1rem;
	line-height: 1.5;
	margin: 0 0 0.5em 0;
}
h5, .h5 {
	font-weight: 400;
	color: $headingColor;
	font-size: 1rem;
	line-height: 1.5;
	margin: 0 0 0.5em 0;
}
h6, .h6 {
	font-weight: 400;
	color: $headingColor;
	font-size: 1rem;
	line-height: 1.5;
	margin: 0 0 0.5em 0;
}

/* Paragraphs */

p {
	margin: 0 0 1em 0;
}
address {
	font-style: normal;
}
cite {
	font-style: normal;
}

/* Lists */

ul, ol {
	list-style: none;
	margin: 0 0 1em 0;
}
ul li {
	margin: 0 0 0.25em 0;
	padding: 0 0 0 1em;
}
ul.unstyled li {
	padding: 0;
	background: none;
}

ol {
	counter-reset: counter;
	margin-left: 0;
	*margin-left: 54px;
}
ol > li {
	margin: 0 0 0.5em 0;
	padding: 0 0 0 1em;
	*list-style-type: decimal;
	counter-increment: counter;
	position: relative;

	&:before {
		color: $gray;
		content: counter(counter) ". ";
		position: absolute;
		left: 0;
		top: 0;
	}
}

dl {
	margin: 0 0 1em 0;
}
dt {
	font-weight: bold;
}

.text-small {
	font-size: 0.8rem;
}
.text-bigger, .entry.text-bigger {
	font-size: 1rem;
}

.text-light {
	font-weight: 300;
}
.text-regular {
	font-weight: 400;
}
.text-medium, strong {
	font-weight: 500;
}
.text-bold {
	font-weight: 700;
}

.text-uppercase {
	text-transform: uppercase;
}
.text-nowrap {
	white-space: nowrap;
}

.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}

.color-gray {
	color: $gray !important;
}

.f-l {
	float: left;
}
.f-r {
	float: right;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $gray;
	margin: 1em 0;
	padding: 0;
}

sup,
sub {
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}
sup {
	bottom: 1ex;
}
sub {
	top: 0.5ex;
}

/* Rows / sections spacing inside an article, page... */

.article > .row:not(:first-child) {
	margin-top: 1.5rem;
}

/* Text content */

.quote, .entry.quote {
	font-size: 1.85rem;
	line-height: 1.3;
	font-weight: 500;
	padding-left: 2.8rem;
	padding-top: 4.5rem;
	position: relative;

	figcaption {
		font-size: 0.9rem;
		font-weight: 300;
	}

	&:before {
		content: "„";
		display: block;
		font-size: 6rem;
		font-weight: 800;
		position: absolute;
		left: 1.5rem;
		top: -3.25rem;
		line-height: 1;
	}
}

.entry {

	font-size: 1rem;
	line-height: 1.5;
	font-weight: 300;

	&.perex {
		font-size: 1.5rem;
		line-height: 1.35;
		font-weight: 300;

		&-big {
			font-size: 1.85rem;
			line-height: 1.25;
			font-weight: 500;
		}

		&-contact {
			font-size: 1.25rem;
			line-height: 1.3;
		}
	}

	p, ul, ol, li, table {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	h2 {
		margin-top: 1.5rem;
	}

	h3 {
		margin-top: 1.25rem;
	}

	h4, h5, h6 {
		margin-top: 1rem;
	}

	h2, h3, h4, h5, h6 {
		&:first-child {
			margin-top: 0;
		}
	}

	figure, table {
		margin: 1.25rem 0 1rem 0;

		&:first-child {
			margin-top: 0.25rem;
		}

		&:last-child {
			margin-bottom: 0.25rem;
		}
	}

	ul > li {
		position: relative;
		padding-left: 1.25rem;
		margin-bottom: 0.65rem;
		font-size: 0.9rem;
		line-height: 1.2;

		&:before {
			content: "";
			position: absolute;
			left: 0rem;
			top: 0.55rem;
			width: 0.6rem;
			height: 1px;
			display: block;
			background: rgba($black, 0.3);
			border-radius: 50%;
		}
	}

	ol > li {
		position: relative;
		padding-left: 2.5rem;
		margin-bottom: 0.75rem;

		&:before {
			left: 0.9rem;
			top: 0;
		}
	}

	.btn {
		margin-bottom: 0.25rem;
	}

	&:not(.entry-alt) {
		a:not(.btn) {
			padding-left: 3rem;
			position: relative;
			display: inline-block;

			&:before {
				content: "";
				display: block;
				width: 2.5rem;
				height: 1px;
				background: $black;
				position: absolute;
				left: 0;
				top: 60%;
				transition: width 0.15s ease-out;
			}

			&:hover, &:active, &:focus {
				&:before {
					background: $black;
					opacity: 0.5;
				}
			}
		}
	}
}

/* laptop < 1370 */
@media screen and (max-width: $xl) {
	h1, .h1 {
		font-size: 2.2rem;
	}
}

@media screen and (max-width: $lg) {
	h1, .h1 {
		font-size: 2rem;
	}
}

@media screen and (max-width: $md) {
}

/* mobile < 768 */
@media screen and (max-width: $sm) {
	.text-sm-left {
		text-align: left !important;
	}

	.perex, .entry.perex, .perex-big, .entry.perex-big {
		font-size: 1.1rem;
	}
	.quote, .entry.quote {
		font-size: 1.25rem;
	  	padding-top: 1rem;

	  &:before {
		font-size: 3rem;
	  }
	}
}

@media screen and (max-width: $xs) {
	.item-list .item {
		width: 100% !important;
	}

	.text-xs-left {
		text-align: left !important;
	}
}

@media screen and (max-width: $xxs) {
}

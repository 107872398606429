.form-std {
	label {
		font-weight: 400;
		font-size: 0.8rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		margin: 0 0 10px 0;
		display: block;
	}

	input.text {
		font-weight: 400;
		font-size: 0.8rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		border: 1px solid $gray;
		padding: 8px 15px 6px 15px;
		width: 100%;
	}

	textarea {
		font-weight: 400;
		font-size: 0.8rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		resize: vertical;
		border: 1px solid $gray;
		padding: 8px 15px;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
		max-height: 300px;
	}

	select {
		font-weight: 400;
		font-size: 0.8rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		width: 100%;
		padding: 2px 10px;
	}

	option {
		font-weight: 400;
		font-size: 0.8rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		padding: 2px 20px;
	}

	input.text[required="required"] {
		background-image: url("../img/req.png");
		background-position: right center;
		background-repeat: no-repeat;
	}
}

.footer {
	background: none;
	padding: 2.5rem 0 2.5rem 0;
	border-top: $border;

	/* sticky footer */
	flex-shrink: 0;

	color: $black;

	a {
		color: $black;
		text-decoration: none;
	}

	&-head {
		font-size: 0.7rem;
		margin-bottom: 0.4rem;
	}

	&-links {
		font-size: 0.85rem;
		display: flex;
		flex-wrap: wrap;

		li {
			position: relative;
			padding-left: 0;

			&:not(:first-child) {
				padding-left: 1.25rem;

				&:before {
					content: "|";
					display: block;
					width: 1px;
					height: 0.7rem;
					font-size: 0.7rem;
					line-height: 1;
					color: $gray;
					position: absolute;
					left: 0.6rem;
					top: 0.3rem;
				}
			}
		}

		&:not(:focus-within):not(:hover) a {
			color: $black;
		}

		a {
			color: rgba($black, 0.5);
			font-weight: 700;
			@include colorTransition();

			&:hover, &:active, &:focus {
				color: $black;
			}
		}
	}

	&-content {
		position: relative;
	}
}

@keyframes alternate {
	0%   {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-0.5rem);
	}
	100% {
		transform: translateY(0);
	}
}

@media screen and (max-width: $sm) {
	.footer {
		margin-top: 2rem;
	}
}
.header {
	height: 3.5rem;
	width: 100%;
	padding: 0;
	background: $white;
	z-index: 2000;
	position: fixed;
	top: 0;
	left: 0;
	transition: top 0.6s ease-out;

	&-logo {
		display: block;
		position: absolute;
		left: 15px;
		top: 1.2rem;
		width: 9.75rem;
	}

	// header animations
	&-logo, .main-nav {
		transform: translateY(1rem);
		opacity: 0;
		animation-name: header-animation;
		animation-duration: 0.8s, 0.8s;
		animation-timing-function: ease-in-out, ease-in-out;
		animation-fill-mode: forwards, forwards;
		animation-delay: 0.2s;
	}

	&-scrolled {
		position: fixed;
		top: -3.5rem;
		left: 0;

		transition: top 0.2s ease-in-out;
	}
}

@keyframes header-animation {
	0%   {
		transform: translateY(1rem);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/* Main navigation */

.main-nav {
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;

	ul {
		margin: 0;

		li {
			padding: 0;
			margin: 0;
			background: none;
			display: inline-block;
		}

		&:not(:focus-within):not(:hover) .main-nav-item {
			color: $black;
		}
	}

	&-item {
		color: rgba($black, 0.5);
		font-size: 0.9em;
		display: block;
		line-height: 3.5rem;
		white-space: nowrap;
		padding: 0 0.75rem;
		text-decoration: none;
		@include colorTransition();

		&:hover, &:active, &:focus {
			color: $black;
		}

		&.active {
			color: $black;
		}
	}
}

/* Mobile menu */

.menu-trigger {
	border: none;
	width: 2.75rem;
	height: 2.75rem;
	position: absolute;
	right: 0.7em;
	top: 0.5em;
	display: none;
	text-align: center;
	z-index: 3000;
}


@media screen and (max-width: $sm) {
	.menu-trigger {
		display: block;
	}

	.header {
		height: auto;
		min-height: 4rem;
		width: 100vw;

		&-logo {
			top: 1.25rem;
			left: 1.25rem;
			z-index: 1500;
		}

		&-col {
			position: static;
		}
	}

	.main-nav {
		background: $white;
		z-index: 1050;
		width: 100%;
		min-height: 100vh;
		left: 100%;
		margin: 0;
		top: 0;
		padding: 4rem 1.5rem 1rem 1.5rem;
		position: absolute;
		transition: left 0.25s ease-in;

		ul {
			width: 100%;

			li {
				display: block;
				width: 100%;
				padding: 0;

				a {
					line-height: 3.3;
					font-size: 1rem;
					text-decoration: none;
					display: block;
					padding: 0;

					&:after {
						display: none;
					}

					&:hover, &:active, &:focus {
					}
				}
			}
		}
	}

	.menu-open {
		overflow: hidden;
		height: 100vh;

		.main-nav {
			left: 0;
		}

		.icon-menu {
		}
	}
}

//@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;800&display=swap');

$baseFont: 'Sarabun', sans-serif;
$baseFontSize: 20px;
$baseLineHeight: 1.5;
$baseFontWeight: 400;

$baseFontColor: #000000;
$headingColor: #000000;

$white: #ffffff;
$black: #1d1d1b;
$gray: #bdc5c7;
$lightGray: #cccccc;
$blue: #0000be;

$border: 1px solid rgba($black, 0.18);

$xl: 1370px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;
$xxs: 420px;

.cookie-bar {
	background: $blue;
	color: $white;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100vw;
	padding: 1.5rem;
	z-index: 900;

	&-head {
		margin-bottom: 0;
		font-size: 1.3rem;
		font-weight: 800;
		max-width: 8rem;
		line-height: 1.1;
	}

	&-content {
		display: flex;

		&-text {
			font-size: 0.85rem;
			padding-right: 3rem;
		}

		&-small {
			font-size: 0.7rem;
		}

		a {
			text-decoration: underline;

			&:hover, &:active, &:focus {
				text-decoration: none;
			}

			&:not(:first-of-type) {
				padding-left: 1.5rem;
			}
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: $white;
	}

	.btn {
		background: none;
		border: 1px solid $white;
		width: 4rem;
		height: 4rem;
		color: $white;
		font-size: 1.4rem;
		text-align: center;
		line-height: 2.8rem;
		text-decoration: none;
		font-weight: 800;
	}
}

@media screen and (max-width: $sm) {
	.cookie-bar {

		&-head {
			margin-bottom: 1.5rem;
			max-width: none;
		}

		&-content {
			flex-wrap: wrap;
		}

		.btn {
			margin-top: 1.5rem;
		}
	}
}
.entry table {
	width: 100%;
	margin: 0 0 30px 0;
}
.entry table th {
	font-weight: normal;
	border-bottom: 1px solid $gray;
	padding: 14px 30px;
	background: $white;
	color: $black;
}
.entry table td {
	border-bottom: 1px solid $gray;
	padding: 14px 30px;
}

.content {
	padding: 3.5rem 0 0 0;

	/* sticky footer */
	flex: 1 0 auto;
}

/* Block */

.block {
	padding: 5rem 0;

	&-narrow {
		padding: 3.5rem 0;
	}

	&-alt {
		background: $lightGray;
	}

	&-alpha {
		padding-top: 0 !important;
	}

	&-omega {
		padding-bottom: 0 !important;
	}
}

/* Content */

.text-block {
	&-head {
		font-size: 2.75rem;

		&-small {
			font-size: 0.9rem;
			font-weight: 400;
			margin-top: 0.8rem;
			margin-bottom: 2.7rem;
		}
	}
}

.project-list {
	.item {
		width: 33.3333%;
	}
}

.project-list-related {
	border-top: $border;
	padding: 3.5rem 0 0 0;

	.item {
		width: 25%;
	}
}

.project {
	&-item {
		margin-bottom: 4.9rem;

		&-big {
			margin-bottom: 6.5rem;
		}

		&-filter {
			margin-top: -0.8rem;
		}
	}

	&-item-inner {
		display: block;
		text-decoration: none;
		color: $black;
		@include colorTransition();

		&:hover, &:active, &:focus &:not(.project-filter-wrapper) {
			color: rgba($black, 0.8);

			.project-title {
				color: rgba($black, 0.8);
			}
		}
	}

	&-img {
		margin-bottom: 1.4rem;
		overflow: hidden;

		img {
			width: 100%;
			height: auto;
		}
	}

	&-title {
		font-size: 1.2rem;
		font-weight: 500;
		margin-bottom: 0.25rem;
		@include colorTransition();
	}

	&-subtitle {
		font-size: 0.75rem;
		font-weight: 300;
		margin-bottom: 0.1rem;
	}
}

.project-filter {
	margin-bottom: 0;
	pointer-events: none;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;

	a {
		pointer-events: auto;
	}

	li {
		margin-bottom: 0.3rem;
		padding: 0;
		pointer-events: auto;
		width: auto;
	}

	&:not(:focus-within):not(:hover) {
		.project-filter-item, .project-filter-subitem {
			color: $black;

			&:before, &:after {
				background: $black;
			}
		}
	}

	&-item, &-subitem {
		color: rgba($black, 0.5);
		text-decoration: none;
		font-weight: 800;
		display: inline-block;

		&:hover, &:active, &:focus {
			color: $black;

			&:before, &:after {
				background: $black;
			}
		}
	}

	&-item {
		font-size: 2.2rem;
		line-height: 1.25;
	}

	&-subitem {
		font-size: 1.4rem;
		line-height: 1.3;
	}

	&-item-toggle {
		position: relative;
		padding-right: 2.35rem;

		&:before, &:after {
			content: "";
			display: block;
			width: 1.25rem;
			height: 1px;
			background: rgba($black, 0.5);
			position: absolute;
			right: 0.35rem;
			top: 1.7rem;
			transform: scale(1);
			transition: right 0.15s ease-out;
		}

		&:after {
			transform: rotate(90deg);
			transform-origin: center center;
			transition: transform 0.15s ease-out, right 0.15s ease-out;
		}

		&.active:after {
			transform: rotate(0);
		}

		&:hover {
			&:before, &:after {
				right: 0;
			}
		}
	}

	&-sublist {
		margin: 0;
		padding: 0.8rem 0 0.5rem 0;

		li {
			margin-bottom: 0.6rem;
			padding-left: 1.5rem;
		}
	}
}

.project-detail {
	&-details {
		p, ul, li {
			&:not(:last-child) {
				margin-bottom: 0.6rem;
			}
		}
	}

	&-play {
		&-links {
			display: flex;
		  	margin-top: 0.7rem;
		}

		&-link {
			display: inline-block;
			color: $black;
			margin-left: -3px;
			margin-bottom: 0.3rem;

			&:not(:last-child) {
				margin-right: 0.35rem;
			}

			.icon {
				width: 2.25rem;
				height: 2.25rem;
			}
		}
	}
}

.content-item {
	margin-top: 1rem;
	margin-bottom: 5rem;

	figure:not(:last-child) {
		margin-bottom: 4.5rem;
	}

	&-head {
		font-size: 1.75rem;
		font-weight: 800;
	}
}

.content-item-big {
	margin-top: 1rem;
	margin-bottom: 8.25rem;

	&-head {
		font-size: 1.85rem;
		font-weight: 500;
		margin-bottom: 2rem;
	}
}

.hidden {
  display: none;

}
.video {
	&-head {
		font-size: 1.5rem;
		font-weight: 300;

		.icon {
			fill: $black;
			stroke: $white;
			stroke-width: 1;
			width: 1.9rem;
			height: 1.9rem;
			transform: translateY(0.25rem);
			margin-right: 0.15rem;
		}
	}
}

.content-item {
  	audio {
	  width: 100%;
	}
}

.award {
	&-item {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 2.5rem;
		}
	}

	&-content {
		width: 62%;
		font-weight: 300;
		font-style: 1rem;
	}

	&-img {
		width: 38%;
		padding-left: 6rem;
	}

	&-year {
		font-weight: 500;
		display: inline-block;
		margin-right: 0.75rem;
	}
}

.reference {
	&-link {
		font-size: 1.2rem;
		font-weight: 500;
		color: $black;
	}
}

.author {
	margin-bottom: 5rem;

	&-left {
		.author-content {
			padding-left: 2.75rem;
		}
	}
	&-right {
		.author-content {
			padding-right: 2.75rem;
		}

		.author-img figcaption {
			text-align: right;
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 100%;

		.btn {
			margin-top: 0.75rem;
		}
	}

	&-head {
		font-size: 1.85rem;
		font-weight: 500;
		margin-bottom: 2rem;
	}

	&-contact {
		font-size: 1.25rem;
		font-weight: 300;
		margin-top: 3.5rem;

	}

	&-img {
		figcaption {
			position: absolute;
			font-size: 0.85rem;
			color: rgba($black, 0.6);
			font-weight: 300;
			line-height: 1.3;
			margin-top: 1rem;
			letter-spacing: 0.01rem;
			display: block;
			width: 100%;
		}
	}

	&-amp {
		font-weight: 800;
		font-size: 3rem;
		line-height: 3rem;
		margin-bottom: 5.25rem;
	}
}

.person {


	&-name {
		font-size: 1.5rem;
		font-weight: 300;
		margin-bottom: 0.3rem;
	}

	&-description {
		margin-bottom: 1.2rem;
	}

	&-list {
		margin-bottom: 6rem;
	}
}

.contact {
	margin-bottom: 2rem;
	font-weight: 300;

	&-head {
		font-size: 1rem;
		font-weight: 500;
		margin-bottom: 0;
	}

	p, ul, ol, li {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.img-color-hover {
	filter: grayscale(100%);
	transition: filter 0.2s ease-in;

	&:hover, &:active, &:focus {
		filter: none;
	}
}

.iframe-wrapper-video {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%;

	&-general {
		padding-top: 52.5%;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
}

.iframe-wrapper-play {
	&:before, &:after {
		content: "";
		display: block;
		position: absolute;
		transition: opacity 0.2s ease-out;
	}

	&:before {
		width: 100%;
		height: 100%;
		background: rgba($black, 0.7);
		z-index: 2000;
		left: 0;
		top: 0;
	}

	&:after {
		width: 5rem;
		height: 5rem;
		background: url('../images/icon-video-white.svg') center center no-repeat;
		background-size: 100% 100%;
		z-index: 2010;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.8;
	}

	&:hover, &:active, &:focus {
		cursor: pointer;

		&:after {
			opacity: 1;
		}
	}

	&.active {
		&:before, &:after {
			opacity: 0;
			pointer-events: none;
		}
	}
}

.iframe-wrapper {
	iframe {
		width: 100%;
	}
}

.editmode {
	.to-anim-project, .to-anim-img-wrapper, .to-anim-project img, .to-anim-img, .to-anim-text-1, .to-anim-text-2, .to-anim-text-3, .header-logo, .main-nav {
	  transform: none !important;
	  opacity: 1 !important;
	}

	.tab {
	  position: relative !important;
	  opacity: 1 !important;
	  pointer-events: auto !important;
	  margin-bottom: 2rem !important;
	}
}

.scroll-top {
	position: absolute;
	right: 0rem;
	bottom: 1.5rem;
	display: block;
	padding: 0.75rem;
	z-index: 1000;

	.icon {
		width: 1.6rem;
		height: 1.6rem;
	}

	&:hover, &:active {
		animation-name:alternate;
		animation-duration: 0.6s;
		animation-timing-function: ease-out;
		animation-iteration-count: 1;

		.icon {
		  	fill: rgba($black, 0.5);
		}
	}

  	&-wrapper {
		position: sticky;
		bottom: 0;
	}
}

.published-item {
	display: flex;
	flex-direction: row;
	font-size: 1.1rem;
	font-weight: 300;
	color: $black;
	padding-left: 0;
	margin-bottom: 10px;

	a {
		display: flex;
		flex-direction: row;

		&:before {
			content: "";
			display: block;
			min-width: 2rem;
			height: 1px;
			background: $black;
			position: relative;
			top: .9rem;
			margin: 0 1rem;
		}
	}
}

@media screen and (max-width: 1760px) {
	.project-item {
		margin-bottom: 5rem;
	}
	.project-filter-item {
		font-size: 2.1rem;
		line-height: 1.2;

		&-toggle {
			&:before, &:after {
				top: 1.6rem;
			}
		}
	}
	.project-filter-sublist {
		padding: 0.6rem 0 0.5rem 0;
	}

}
@media screen and (max-width: 1660px) {
	.project-filter-item {
		font-size: 1.7rem;
		line-height: 1.3;

		&-toggle {
			&:before, &:after {
				top: 1.3rem;
				width: 1.1rem;
			}
		}
	}
	.project-filter-sublist {
		padding: 0.5rem 0 0.3rem 0;
	}
	.project-filter-subitem {
		font-size: 1.2rem;
	}
}
@media screen and (max-width: 1560px) {
	.project-filter-sublist li {
		margin-bottom: 0.4rem;
	}

}

@media screen and (max-width: 1450px) {
	.author {
		&-head {
			margin-bottom: 1.5rem;
		}

		&-contact {
			font-size: 1.25rem;
			font-weight: 300;
			margin-top: 1.5rem;

		}
	}
}

@media screen and (max-width: 1360px) {

}

@media screen and (max-width: $xl) {

}

@media screen and (max-width: $lg) {

	.project-list {
		.item {
			width: 50%;
		}
	}
}

@media screen and (max-width: $md) {
	.services {
		.item {
			width: 50%;
		}
	}

	.scroll-top-wrapper {
	  	display: none;
	}

	.content-item {
		margin-bottom: 3rem;

		figure:not(:last-child) {
			margin-bottom: 2.5rem;
		}
	}

	.content-item-big {

		margin-bottom: 5rem;

		&-head {
			margin-bottom: 1rem;
		}
	}

}

@media screen and (max-width: $sm) {
	.block {
		padding: 1.5rem 0;
	}

	.project-list {
		.item {
			width: 100%;
		}
	}

	.award {
		&-img {
			padding-left: 1.5rem;
		}

	}

	.author {

		&-content {
			padding: 0 !important;
		}

		&-img {
			figcaption {
				position: relative;
				text-align: left !important;
			}

			margin-bottom: 2rem;
		}

	  	&-contact {
			a {
			  	width:100%;
			}
		}

	}

	.project-name {
		font-size: 1.25rem;
		font-weight: 400;
	}

  	.project-item {
	  	margin-bottom: 3rem;

		.project-img {
		  	margin-bottom: 0.3rem;
		}
	}

  	.content-item-big {
	  margin-bottom: 3rem;
	}

}

@media screen and (max-width: $xs) {
	.award {
		&-item {
			flex-wrap: wrap;
		}

		&-content {
			order: 2;
		}

		&-img {
			width: 100%;
			padding-left: 0;
			order: 1;
			margin-bottom: 1.25rem;
			margin-top: 0.5rem;
		}
	}

	.published-item {
		a {
			&:before {
				min-width: 1rem;
				margin: 0 .5rem;
			}
		}
		&-name {
			flex-shrink: 0;
		}
	}
}

@media screen and (max-width: $xxs) {
}

.btn {
	font-size: 0.7rem;
	text-transform: uppercase;
	display: inline-block;
	vertical-align: middle;
	border: none;
	background: none;
	color: $black;
	font-weight: 400;
	padding: 0.5rem 0;
	text-decoration: none;
	position: relative;

	&:hover, &:active, &:focus {
		color: $black;
	}

	&-line {
		padding: 0.5rem 0.5rem 0.5rem 3.25rem;
		transition: padding 0.15s ease-out;

		&:before {
			content: "";
			display: block;
			width: 2.5rem;
			height: 1px;
			background: $black;
			position: absolute;
			left: 0;
			top: 50%;
			transition: width 0.15s ease-out;
		}

		&:hover, &:active, &:focus {
			padding-left: 4.25rem;

			&:before {
				width: 3.5rem;
			}
		}
	}

	&-icon {
		padding: 0;

		.icon {
			transform: scale(1);
			transition: transform 0.1s ease-out;
			transform-origin: center center;
		}

		&:hover, &:active, &:focus {
			.icon {
				transform: scale(1.1);
			}
		}
	}

	&-more {
		padding: 2.35rem 0.5rem 0.5rem 0.5rem;
		visibility: hidden;

		&-icon {
			display: block;
			width: 4px;
			height: auto;
			position: absolute;
			left: 50%;
			top: 0.5rem;
			margin-left: -1px;

			&-line {
				display: block;
				width: 3px;
				height: 3px;
				background: $black;
				border-radius: 50%;
				position: absolute;
				left: 0;
				//transform: scale(1);
				transform-origin: center center;

				&:nth-child(1) {
					top: 0%;
					//animation-delay: 0.1s;
				}

				&:nth-child(2) {
					top: 50%;
					//animation-delay: 0.2s;
				}

				&:nth-child(3) {
					top: 100%;
					//animation-delay: 0.3s;
				}
			}
		}

		&:hover, &:active, &:focus {
			.btn-more-icon-line {

				animation-duration: 0.3s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-direction: alternate;

				&:nth-child(1) {
					top: 0%;
					animation-name: hvr-icon-pulse-shrink-top;
				}

				&:nth-child(2) {
					top: 50%;
					animation-name: hvr-icon-pulse-shrink;
				}

				&:nth-child(3) {
					top: 100%;
					animation-name: hvr-icon-pulse-shrink-bottom;
				}
			}
		}
	}
}

.btn-line-entry {
	padding-left: 3rem;
	position: relative;
	display: inline-block;

	&:before {
		content: "";
		display: block;
		width: 2.5rem;
		height: 1px;
		background: $black;
		position: absolute;
		left: 0;
		top: 60%;
		transition: width 0.15s ease-out;
	}

	&:hover, &:active, &:focus {
		&:before {
			background: $black;
			opacity: 0.5;
		}
	}
}

@keyframes hvr-icon-pulse-shrink {
	to {
		transform: translateY(0);
	}
}

@keyframes hvr-icon-pulse-shrink-top {
	to {
		//transform: scale(0.8) translateY(5px);
		transform: translateY(4px);
	}
}

@keyframes hvr-icon-pulse-shrink-bottom {
	to {
		//transform: scale(0.8) translateY(-5px);
		transform: translateY(-4px);
	}
}

.prev-next-wrapper {
	display: flex;
	align-items: flex-end;
}

.prev-next {
	display: flex;

	.prev, .next {
		position: relative;

		&:hover, &:active, &:focus {
			.prev-arrow, .next-arrow {
				&:before {
					transform: rotate(45deg);
				}
				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}

	.prev-arrow, .next-arrow {
		display: block;
		width: 1.25rem;
		height: 1px;
		background: $black;
		position: absolute;
		top: 1rem;

		&:before, &:after {
			content: "";
			display: block;
			width: 0.5rem;
			height: 1px;
			background: $black;
			position: absolute;
			top: 0;
			transition: transform 0.1s ease-out;
		}
	}

	.prev {
		padding-right: 2rem;

		&-arrow {
			right: 0;

			&:before, &:after {
				left: 0;
				transform-origin: left center;
			}
		}

	}

	.next {
		padding-left: 2rem;

		&-arrow {
			left: 0;

			&:before, &:after {
				right: 0;
				transform-origin: right center;
			}
		}
	}
}

.prev-next-bottom-wrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 0 auto;

	.prev-next-text {
		text-transform: none;
	}

	.prev-next {
		justify-content: space-between;

		.prev {
			padding-right: 0;

			&-arrow {
				left: 0;
				right: auto;
			}
		}

		.next {
			padding-left: 0;
			&-arrow {
				right: 0;
				left: auto;
			}
		}

		.prev-arrow-text {
			padding-left: 2rem;
			margin-bottom: .5rem;
		}

		.next-arrow-text {
			width: 100%;
          	text-align: right;
			padding-right: 2rem;
			margin-bottom: .5rem;
		}

		.category-btn {
			position: absolute;
			text-align: center;
			width: 33%;
			left: 33%;

			&:hover, &:active {
				.top-arrow {
					&:before {
						transform: rotate(45deg);
						transform-origin: 0 0;
					}

					&:after {
						transform: rotate(-45deg);
						transform-origin: 0 0;
					}
				}
			}
		}
	}

	.top-arrow {
		width: 1px;
		height: 1rem;
		background: $black;
		position: absolute;
		top: 6px;
		margin-left: 1rem;

		&:before, &:after {
			content: "";
			display: block;
			width: 1px;
			height: 0.5rem;
			background: $black;
			position: absolute;
			top: 0;
			transition: transform 0.1s ease-out;
		}
	}
}

@media screen and (max-width: $xs) {
	.prev-next-bottom-wrapper {
		.prev-next {
			h3 {
				font-size: 1rem;
			}

			.next {
				padding-right: .3rem;
			}

			.prev {
				padding-left: .3rem;
			}

			.prev-arrow-text {
				margin-bottom: 1.2rem;
			}

			.next-arrow-text {
				margin-bottom: 1.2rem;
			}

			.category-btn {
				left: 23%;
				width: 50%;
			}
		}
	}
}

html {
	height: 100%;
	font-size: $baseFontSize;
	overflow-x: hidden;
	position: relative;
}

body {
	font-family: $baseFont;
	font-size: $baseFontSize;
	line-height: $baseLineHeight;
	color: $baseFontColor;
	background: $white;
	font-weight: $baseFontWeight;
	margin: 0;
	padding: 0;
	min-height: 100%;
	max-width: 100vw;
	//overflow-x: hidden;
	position: relative;

	/* sticky footer */
	display: flex;
	flex-direction: column;
}

/* Box sizing */

*, *:after, *:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Forms */

form {
	display: block;
}
fieldset {
	border: none;
}
label {
	font-weight: bold;
}
input, select, button {
	vertical-align: top;
}
a, a:hover, a:active, a:focus {
	outline: 0;
}

/* Various */

img {
	max-width: 100%;
	height: auto;
}

figure {
	figcaption {
		font-size: 1rem;
		font-weight: 300;
		margin: 0.5rem 0 0 0;

		p {
			margin: 0;
		}
	}

	img {
		width: 100%;
	}
}

.last {
	margin-bottom: 0 !important;
}

.sh-content {
	display: none;
}

/* Alignment */

.pull-right {
	margin-left: auto;
}

.d-block { display: block; }
.d-flex { display: flex; }

.flex-wrap { flex-wrap: wrap; }

.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }

.flex-grow-1 {
  flex-grow: 1;
}

.col-set {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.col2-set .col {
	width: 50%;
	margin-bottom: 1em;
}
.col3-set .col {
	width: 33.33333%;
	margin-bottom: 1em;
}
.col4-set .col {
	width: 25%;
	margin-bottom: 1em;
}

/* Item lists */

.item-list {
	margin: 0 0 0 -1.5em;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.item {
		padding-left: 1.5em;
	}

	a.item-inner {
		display: block;
		text-decoration: none;
	}

	&.item-list-1 .item {
		width: 100%;
	}

	&.item-list-2 .item {
		width: 50%;
	}

	&.item-list-3 .item {
		width: 33.33333%;
	}

	&.item-list-4 .item {
		width: 25%;
	}
}


/* Layout */

@for $i from 0 through 8 {
	.m-t-#{$i} {
		margin-top: #{$i}rem !important;
	}
	.m-b-#{$i} {
		margin-bottom: #{$i}rem !important;
	}
	.m-l-#{$i} {
		margin-left: #{$i}rem !important;
	}
	.m-r-#{$i} {
		margin-right: #{$i}rem !important;
	}

	.m-t--#{$i} {
		margin-top: -#{$i}rem !important;
	}
	.m-b--#{$i} {
		margin-bottom: -#{$i}rem !important;
	}

	.p-t-#{$i} {
		padding-top: #{$i}rem !important;
	}
	.p-b-#{$i} {
		padding-bottom: #{$i}rem !important;
	}
	.p-l-#{$i} {
		padding-left: #{$i}rem !important;
	}
	.p-r-#{$i} {
		padding-right: #{$i}rem !important;
	}
}

.m-b-0-25 {
	margin-bottom: 0.25rem !important;
}
.m-b-0-5 {
	margin-bottom: 0.5rem !important;
}
.m-b-0-75 {
	margin-bottom: 0.75rem !important;
}
.m-b-1-25 {
	margin-bottom: 1.25rem !important;
}
.m-b-1-5 {
	margin-bottom: 1.5rem !important;
}
.m-b-1-75 {
	margin-bottom: 1.75rem !important;
}
.m-b-2-5 {
	margin-bottom: 2.5rem !important;
}

.m-t-0-25 {
	margin-top: 0.25rem !important;
}
.m-t-0-5 {
	margin-top: 0.5rem !important;
}
.m-t-0-75 {
	margin-top: 0.75rem !important;
}
.m-t-1-25 {
	margin-top: 1.25rem !important;
}
.m-t-1-5 {
	margin-top: 1.5rem !important;
}
.m-t-1-75 {
	margin-top: 1.75rem !important;
}
.m-t-2-5 {
	margin-top: 2.5rem !important;
}

.m-l-0-25 {
	margin-left: 0.25rem;
}
.m-l-0-5 {
	margin-left: 0.5rem;
}
.m-l-0-75 {
	margin-left: 0.75rem;
}
.m-l-1-5 {
	margin-left: 1.5rem;
}
.m-l-2-5 {
	margin-left: 2.5rem;
}

.m-r-0-25 {
	margin-right: 0.25rem;
}
.m-r-0-5 {
	margin-right: 0.5rem;
}
.m-r-0-75 {
	margin-right: 0.75rem;
}
.m-r-1-5 {
	margin-right: 1.5rem;
}
.m-r-2-5 {
	margin-right: 2.5rem;
}

.p-b-0-25 {
	padding-bottom: 0.25rem !important;
}
.p-b-0-5 {
	padding-bottom: 0.5rem !important;
}
.p-b-0-75 {
	padding-bottom: 0.75rem !important;
}
.p-b-1-5 {
	padding-bottom: 1.5rem !important;
}

.p-t-0-25 {
	padding-top: 0.5rem !important;
}
.p-t-0-5 {
	padding-top: 0.5rem !important;
}
.p-t-0-75 {
	padding-top: 0.5rem !important;
}
.p-t-1-5 {
	padding-top: 1.5rem !important;
}

.no-margin {
	margin: 0 !important;
}
.no-padding {
	padding: 0 !important;
}

/* Mixins */

@mixin colorTransition() {
	transition: color 0.15s ease-in;
}

/* laptop < 1370 */
@media screen and (max-width: $xl) {
	html {
		font-size: 19px;
	}
}

@media screen and (max-width: $lg) {
	.m-b-lg-0 {
		margin-bottom: 0 !important;
	}

	.m-b-lg-1 {
		margin-bottom: 1rem !important;
	}

	.m-b-lg-4 {
		margin-bottom: 4rem !important;
	}
}

@media screen and (max-width: $md) {
	.m-b-md-0 {
		margin-bottom: 0 !important;
	}

	.m-b-md-1 {
		margin-bottom: 1rem !important;
	}

	.m-b-md-2 {
		margin-bottom: 2rem !important;
	}

	.m-b-md-4 {
		margin-bottom: 4rem !important;
	}

	.m-t-md-0 {
		margin-top: 0 !important;
	}

	.m-t-md-1 {
		margin-top: 1rem !important;
	}

	.m-t-md-2 {
		margin-top: 2rem !important;
	}

	.m-t-md--1 {
		margin-top: -1rem !important;
	}

	.m-t-md--2 {
		margin-top: -2rem !important;
	}
}

/* mobile < 768 */
@media screen and (max-width: $sm) {
  	.m-t-sm-0 {
	  	margin-top: 0!important;
	}

  	.m-b-sm-0 {
	  	margin-bottom: 0!important;
	}

  	.m-b-sm-1-5 {
	  margin-bottom: 1.5rem!important;
	}
}

@media screen and (max-width: $xs) {
	.item-list .item {
		width: 100% !important;
	}

	.mobile-hidden {
		display: none;
	}
}

@media screen and (max-width: $xxs) {
}

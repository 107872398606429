/* Images in frame */

.to-anim {
	&-project, &.to-anim-project, &-img-wrapper, &-text-1, &-text-2, &-text-3 {
		transform: translateY(2rem);
		opacity: 0;
	}

	&-img {
		transform: scale(1.05);
		transform-origin: center top;

		&-wrapper {
			overflow: hidden;
		}
	}

	&-project img {
		transform: scale(1.05);
		transform-origin: left top;
	}
}

.anim {
	// move + fade to all
	.to-anim-project, &.to-anim-project, .to-anim-img-wrapper, .to-anim-text-1, .to-anim-text-2, .to-anim-text-3 {
		animation-name: move-top-top, fade;
		animation-duration: 0.8s, 0.8s;
		animation-timing-function: ease-in-out, ease-in-out;
		animation-fill-mode: forwards, forwards;
	}

	// zoom to images
	.to-anim-img, .to-anim-project img {
		animation-name: zoomOut;
		animation-duration: 0.6s;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
		animation-delay: 0.2s;
	}

	//delays

	.to-anim-project, &.to-anim-project, .to-anim-img-wrapper {
		animation-delay: 0.2s, 0.2s;
	}

	.to-anim-text-1 {
	   animation-delay: 0.2s, 0.2s;
	}

	.to-anim-text-2 {
	   animation-delay: 0.3s, 0.5s;
	}

	.to-anim-text-3 {
	   animation-delay: 0.4s, 0.7s;
	}
}

@keyframes move-top-top {
	0%   {
		transform: translateY(2rem);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes fade {
	0%   {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes zoomOut {
	0%   {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}
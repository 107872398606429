.slider {
	overflow: hidden;
	background: none;

	// panels
	li.panel {
		overflow: hidden;
		background: none;

		&:not(.slide):not(:first-child) {
			display: none;
		}
	}

	ul, li.panel {
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		display: block;
	}

	// navigation
	&-nav {
		margin-top: 1em;
		display: block;
		text-align: center;
		width: 100%;

		ol {
			margin: 0 0 0 -10px;
		}

		li {
			margin: 0 0 0 10px;
			padding: 0;
			background: none;
			display: inline-block;
			font-size: 0;

			&:before {
				content: none;
			}

			a {
				width: 8px;
				height: 8px;
				display: block;
				background: $gray;
				text-indent: -80000px;
				border-radius: 50%;
				-moz-border-radius: 50%;
				-webkit-border-radius: 50%;
			}

			&.current a {
				background: $black;
			}
		}

		// prev / next buttons
		.prev {
			position: absolute;
			left: -6.5rem;
			top: 50%;
			display: inline-block;
			width: 2em;
			height: 2em;
			transform: translateY(-50%);

			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				display: block;
				width: 1.5em;
				height: 1.5em;
				border-left: 1px solid $black;
				border-bottom: 1px solid $black;
				transform: rotate(45deg) translate3d(0,0,1px);
				transform-origin: 50% 50%;
				z-index: 10;
				margin-top: -0.4em;
			}

			&:hover, &:active {
				border-color: $black;
			}
		}

		.next {
			position: absolute;
			right: -6.5rem;
			top: 50%;
			display: inline-block;
			width: 2em;
			height: 2em;
			transform: translateY(-50%);

			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				display: block;
				width: 1.5em;
				height: 1.5em;
				border-right: 1px solid $black;
				border-top: 1px solid $black;
				transform: rotate(45deg) translate3d(0,0,1px);
				transform-origin: 50% 50%;
				z-index: 10;
				margin-top: -0.4em;
			}

			&:hover, &:active {
				border-color: $black;
			}
		}
	}
}

.item-slider {
	overflow: hidden;

	.slider {
		margin-left: -1.5rem;
	}

	li.panel .item {
		padding-left: 1.5rem;
	}

	li.panel .item-inner {
		padding: 1.5rem;
		background: $lightGray;
	}
}

.gallery-slider {
	position: relative;
}

@media screen and (max-width: $lg) {
	.slider-nav .prev {
		left: -3.5rem;
	}

	.slider-nav .next {
		right: -3.5rem;
	}
}

@media screen and (max-width: $sm) {
	.slider-nav .prev {
		left: -1rem;
	}

	.slider-nav .next {
		right: -1rem;
	}

	.gallery-slider {
		padding: 0 2rem;
	}
}

@media screen and (max-width: $xs) {
	.slider-nav .prev {
		left: -0.25rem;
	}

	.slider-nav .next {
		right: -0.25rem;
	}
}